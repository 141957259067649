import React from 'react';
import { createUseStyles } from 'react-jss';
import { People, Clock } from 'react-bootstrap-icons';
import { ContactButton } from '../layout/contactButton';

const useStyles = createUseStyles((theme) => ({
  container: {
    padding: [[0, 'var(--size-4x)']],
    textAlign: 'center'
  },
  grid: {
    maxWidth: 900,
    margin: [[0, 'auto']],
    display: 'grid',
    justifyContent: 'space-between',
    justifyItems: 'center',
    alignItems: 'center',
    gridTemplateColumns: [[200, 200, 200]],
    textAlign: 'center'
  },
  header: {
    gridRow: '1 / span 3'
  },
  title: {
    fontSize: 'var(--font-size-xxl)',
    fontWeight: 600
  },
  subtitle: {
    fontSize: 'var(--font-size-lg)',
    fontWeight: 500,
    marginBottom: 0
  },
  icon: {
    marginBottom: 'var(--font-size-xs)',
    fill: theme.tableHeaderBlue
  },
  number: {
    fontSize: 'var(--font-size-xxl)',
    fontWeight: 600,
    marginBottom: 'var(--font-size-xs)'
  },
  description: {
    marginBottom: 'var(--font-size-xs)'
  },
  button: {
    margin: [['var(--font-size-md)']],
    display: 'inline'
  },
  [`@media (max-width: ${theme.breakPoints.smMax})`]: {
    container: {
      padding: [[0, 'var(--min-side-padding)']]
    },
    grid: {
      gridTemplateColumns: [[100, 150, 150]]
    }
  }
}));

export const Statistics = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.grid}>
        <header className={classes.header}>
          <h2 className={classes.title}>Statystyki</h2>
          <h3 className={classes.subtitle}>Nasi odbiorcy</h3>
        </header>
        <People size={40} className={classes.icon} />
        <Clock size={40} className={classes.icon} />
        <p className={classes.number}>31,2 tysięcy</p>
        <p className={classes.number}>5 minut</p>
        <p className={classes.description}>czytelników bloga miesięcznie</p>
        <p className={classes.description}>
          średni czas spędzony na czytaniu posta
        </p>
      </div>
      <ContactButton additionalClassName={classes.button} />
    </div>
  );
};
