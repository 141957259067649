import { graphql } from 'gatsby';
import React from 'react';
import { Layout } from '../components/layout/layout';
import { SEO } from '../components/layout/seo';
import { useConfig } from '../hooks/useConfig';
import { createUseStyles } from 'react-jss';
import { SubpageHeader } from '../components/layout/subpageHeader';
import { PictureOffer } from '../components/index-and-offer/pictureOffer';
import { Statistics } from '../components/index-and-offer/statistics';

const useStyles = createUseStyles((theme) => ({
  image: {},
  offers: {
    '& > *': {
      marginTop: 'var(--size-4x)',
      marginBottom: 'var(--size-4x)'
    }
  }
}));

export default function CorporateClients({
  data: {
    photos: { nodes }
  },
  location: { pathname }
}) {
  const {
    blogSEO: { title, description, keywords }
  } = useConfig();
  const classes = useStyles();
  return (
    <Layout
      slug={pathname}
      header={<SubpageHeader title="Oferta - klienci korporacyjni" />}
      maxWidth={1400}
      contentClass={classes.offers}
      limitContentWidth
    >
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={pathname}
      />
      {nodes.map(
        (
          {
            frontmatter: { heading, subheading, photo, followedByStatistics },
            html
          },
          i
        ) => (
          <>
            <PictureOffer
              heading={heading}
              subheading={subheading}
              html={html}
              photo={photo}
              imageClass={classes.image}
              key={i}
              imageRight={i % 2 === 0}
              containerClass={'picture-text-' + i}
              followedByStatistics={followedByStatistics}
            />
            {followedByStatistics && <Statistics />}
          </>
        )
      )}
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    photos: allMarkdownRemark(
      filter: { fields: { collection: { eq: "corporate-offer" } } }
      sort: { fields: fields___slug }
    ) {
      nodes {
        html
        frontmatter {
          heading
          subheading
          followedByStatistics
          photo {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
